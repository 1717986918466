import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  BannerSmall,
  Breadcrumbs,
  TextBox,
  QuickFacts,
  BannerLinks,
  ProjectPagination,
  Image
} from '../../components'

import { generateBreadcrumbsLinks } from '../../utils/index'

import BannerImage from '../../assets/headerbild.pim.jpg'
import img1 from '../../assets/PIM.holzweg.EGLO.jpg'
import image4 from '../../assets/bannerlinks/dibk.dom.jpg'
import image5 from '../../assets/bannerlinks/bannerbox.gsk-gebro.jpg'
import CMSBeratung from '../../assets/bannerlinks/cmsberatung.jpg'
import VerlaesslicheBeratung from '../../assets/bannerlinks/verlaessliche.beratung.jpg'

const boxes = [
  {
    text: 'Individual Software',
    image: CMSBeratung,
    link: '/individual-software',
    linkText: 'Mehr Erfahren'
  },
  {
    text: 'PIM Projekt starten',
    image: VerlaesslicheBeratung,
    link: '/kontakt',
    linkText: 'Kontaktieren Sie uns'
  }
]

const Eglo = () => {
  const links = generateBreadcrumbsLinks(
    '/projekte/eglo-pim',
    'B2B Shopsystem und PIM-System'
  )
  return (
    <Layout>
      <Helmet
        title="B2B Shopsystem und PIM-System"
        meta={[
          {
            name: 'description',
            content:
              'Gemeinsam mit unserem langjährigen Kunden EGLO wurde ein B2B Shopsystem für über 38.000 Produkte ins Leben gerufen. Desweiteren wurden wir vom Weltmarktführer für die Erstellung eines internen Produktinformationsmanagement (PIM) System für über 240.000 Produkte beauftragt.'
          },
          {
            name: 'keywords',
            content:
              'EGLO holzweg, Projekt holzweg, Innsbruck, Webseite holzweg, EGLO Leuchten, Lampen, Produktinformationsmanagement'
          },
          {
            property: 'og:description',
            content:
              'Gemeinsam mit unserem langjährigen Kunden EGLO wurde ein B2B Shopsystem für über 38.000 Produkte ins Leben gerufen. Desweiteren wurden wir vom Weltmarktführer für die Erstellung eines internen Produktinformationsmanagement (PIM) System für über 240.000 Produkte beauftragt.'
          },
          {
            property: 'og:image',
            content: 'https://www.holzweg.com/static/PIM.holzweg.EGLO-5e7eaef62c3d83c1f8a7c6e5f4c5d508.jpg'
          }
        ]}
      />
      <BannerSmall image={BannerImage} title="Produkte ohne Ende" />
      <Breadcrumbs
        links={links}
        backLinkAlias="Übersicht aller aktuellen Projekte"
      />
      <TextBox
        title={
          <div>
            B2B System für
            <br />
            EGLO Leuchten
          </div>
        }
        text={
          <span>

            <p>Gemeinsam mit unserem langjährigen Kunden EGLO wurde vor acht Jahren ein B2B Shopsystem für über 38.000 Produkte ins Leben gerufen. Das B2B System unterstützt den Weltmarktführer für Leuchten beim weltweiten Verkauf in 29 Verkaufsorganisationen.</p>

            <p>Bestehende Shop-Systeme haben sich für die Anforderungen des B2B-Systems als zu unflexibel erwiesen. Deswegen wurde schrittweise das bestehende Magento-Shop-System durch einen individuell entwickelten Shop abgelöst mit dem sich alle Sonderanforderungen effizient umsetzen lassen.</p>

            <p>Ein paar Beispiele für <strong>maßgeschneiderte Features</strong> im Shop:</p>

            <ul>
              <li><strong>Sortiment</strong>: Individuelle Produktlisten auf Kunden-, Kundengruppen- und Verkaufsorganisations-Ebene</li>
              <li><strong>Download-System</strong> für Produkt-Listen, Datenblätter, Bildmaterial, Anleitungen von Gigabyte-Großen Daten</li>
              <li><strong>Automatisierte Benachrichtigungen</strong> zB. bei geänderten Lagerbestand, Sortimentsänderungen oder aktualisierten Bilder</li>
              <li><strong>Merklisten</strong> von Kunden oder Mitarbeiter erstellt</li>
              <li><strong>Leistungsfähige Produkt-Suche</strong></li>
              <li><strong>Einscannen von Produkte</strong> mit QR-Codes, EAN-Barcodes</li>
              <li><strong>Dashboards</strong>: Individuell anpassbar von EGLO-Mitarbeiter für ihre Kunden</li>
              <li><strong>Specialpromo-Portal</strong>: für besondere Verkaufstage</li>
              <li><strong>Kunden-Registrierung</strong>: Kunden können über ein mehrstufiges Verfahren mit unterschiedlichen Rechten und Betreuer für den Shop aktiviert werden</li>
            </ul>

            <p>Für den Datenaustausch mit anderen Systemen weltweit wurden zahlreiche <strong>Schnittstellen</strong> im B2B umgesetzt:</p>

            <ul>
              <li><strong>SAP-System</strong>: Preise, Lagerbestand und andere Daten werden in Echtzeit vom SAP-System abgefragt</li>
              <li><strong>PIM-System</strong>: Produktdaten werden aus einem Akeneo-PIM-System importiert</li>
              <li><strong>Bestellungen</strong>: Die Bestellungen werden über das standardisierte EDIFACT geschrieben. Der Bestellstatus kann über eine SOAP-Schnittstelle verfolgt werden</li>
              <li><strong>DAM-System</strong>: Bilder, Videos und Anleitungen werden vom DAM-System ins B2B importiert</li>
              <li><strong>REST-Schnittstellen</strong>: Es wird mit weiteren externen Systemen über individuelle REST-Schnittstellen kommuniziert</li>
            </ul>

            <p><strong>Eingesetzte Technologien:</strong> NextJS, React, Typescript</p>

          </span>
        }
      />

      <TextBox
        title={
          <div>
            Unser PIM System für EGLO Leuchten
          </div>
        }
        text={

          <span>
            <p>
              Vor einigen Jahren wurde in enger Zusammenarbeit mit unserem langjährigen Kunden EGLO ein hochmodernes Produktinformationsmanagement (PIM) System für über 240.000 Produkte entwickelt. Dieses PIM System diente als Unterstützung für den Weltmarktführer im Bereich Leuchten, um effizient den internen Geschäftsbereich zu verwalten.
            </p>
            <p>
              Das komplexe PIM wurde mit{' '}
              <a href="https://www.akeneo.com/" target="_blank">
                Akeneo
              </a>{' '}
              in seiner Open Source Variante umgesetzt.
            </p>
            <h3>Zusatzfeatures für EGLO Leuchten</h3>
            <p>
              Außerdem wurden neben den standardisierten Features von Akeneo
              noch weitere Features unsererseits umgesetzt:
            </p>
            <ul>
              <li>SAP-Schnittstelle: Bidirektionaler Datenaustausch</li>
              <li>
                Attribute-Rechte: auf Attributsebene eingeschränkte User-Rechte
              </li>
              <li>Produkt-Historie: Versionierung der Produkte</li>
              <li>Easy Catalog: Schnittstelle zum InDesign Plugin</li>
              <li>Workflow: automatische Benachrichtigungen</li>
              <li>Produkt-Links: verknüpfte Produkte</li>
              <li>Angepasste CSV Importe und Exporte</li>
              <li>Public Views über QR Codes</li>
            </ul>
            <p>
            <br />
              <a
                className="button button--color-black"
                href="/downloads/PIM.holzweg.EGLO.pdf"
                target="_blank"
              >
                Download PIM-Blatt als PDF
              </a>
            </p>
          </span>
        } />
      <Image
        image={img1}
        title="Abbildung 1: Übersicht Produktinformationsmanagement EGLO"
      />

      <QuickFacts
        facts={[
          { count: 240000, suffix: '', text: 'Produkte' },
          { count: 686, suffix: '', text: 'Attribute' },
          { count: 159000, suffix: '', text: 'Bilder' }
        ]}
        color="orange"
      />
      <TextBox title="Weitere Projekte" titleMods={['center']} />
      <ProjectPagination
        titleLeft={
          <span>
            Diözese Innsbruck <br />
            Soft Redesign
          </span>
        }
        imageLeft={image4}
        leftLink="/projekte/dioezese"
        titleRight="Haleon-Gebro Multiportal"
        imageRight={image5}
        rightLink="/projekte/haleon-gebro"
        titleModsRight={['white']}
        titleModsLeft={['white']}
      />
      <TextBox
        title="Interesse an einem gemeinsamen Projekt?"
        text="Wir bei holzweg bieten nicht nur maßgeschneiderte B2B-Lösungen an, sondern auch individuelle Softwarelösungen und custom ERP-Systeme. Unsere Experten entwickeln Lösungen, die genau auf die Bedürfnisse unserer Kunden zugeschnitten sind und sie bei der Optimierung ihrer Geschäftsprozesse unterstützen. Kontaktieren Sie uns gerne, um mehr darüber zu erfahren, wie wir Ihnen helfen können, Ihre Geschäftsabläufe zu optimieren und Ihr Unternehmen auf die nächste Stufe zu bringen."
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default Eglo
